<template>
  <div class="site-container page-service-transport">
    <ab-header></ab-header>

    <div class="site-banner">
      <img src="@/assets/img/website/banner-service-transport-domestic.png" />
    </div>

    <div class="site-module">
      <div class="sm-hd">气垫车</div>
      <div class="sm-bd service-lorry">
        <div class="pic">
          <img src="@/assets/img/website/service-lorry-1.png" />
        </div>
        <div class="pic">
          <img src="@/assets/img/website/service-lorry-2.png" />
        </div>
        <div class="text">
          <p>・气垫车型：箱式气垫车（恒温恒湿）、平板气垫车（高低板、ro/ro板）。</p>
          <p>・“一站式”综合物流服务，国内网络覆盖全面，丰富的项目操作经验，车辆资源充足，能够满足客户的个性化需求。</p>
          <p>・集中约价，优质实惠。</p>
        </div>
      </div>
    </div>

    <div class="site-module">
      <div class="sm-hd">危险品运输</div>
      <div class="sm-bd service-danger">
        <div class="text">
          <p>・熟悉半导体治具类危险品的国内运输：2类、3类、4类、5类、6类、8类、9类。</p>
          <p>・国内网络覆盖全面，车辆资源丰富。</p>
          <p>・熟悉不同类别的危险品，运输备案登记工作。</p>
        </div>  
        <div class="pic">
          <img src="@/assets/img/website/service-danger-1.png" />
        </div>
        <div class="pic">
          <img src="@/assets/img/website/service-danger-2.png" />
        </div>      
      </div>
    </div>

    <ab-footer></ab-footer>
  </div>
</template>

<script>
export default {
  name: 'service-transport-domestic',
  components: {

  },

  data () {
    return {
      
    }
  },

  computed: {

  },

  watch: {

  },

  mounted() {

  },

  methods: {
    
  }
}
</script>

<style lang="scss">
.page-service-transport {
  .service-lorry, .service-danger {
    position: relative;
    display: flex;
    justify-content: space-between;
    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      z-index: 1;
      width: 610px;
      height: 209px;
      border: 2px solid #80d7ff;
    }
    .pic {
      position: relative;
      z-index: 2;
      width: 300px;
      height: 209px;
      margin-right: 10px;
      img {
        width: 300px;
        height: 209px;
      }
    }
    .text {
      flex: 1;
      margin-left: 48px;
      font-size: 18px;
      line-height: 36px;
      color: #000;
    }
  }
  .service-danger {
    &::before {
      left: auto;
      right: 6px;
    }
    .pic {
      margin-left: 10px;
      margin-right: 0;
    }
    .text {
      margin-right: 48px;
      margin-left: 0;
    }
  }
}
</style>
